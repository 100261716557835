import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/section";
import MainHeading from "../components/main-heading";
import ImageText from "../components/image-text";
import Li from "../components/li";
import UiLink from "../components/ui-link";
import UiLinkContainer from "../components/ui-link-container";

import mainImg from "../images/undraw_setup_wizard_r6mr.svg";
import tickIcon from "../images/icons/tick.svg";

function KontrolaPrispevkuPage() {
    return (
      <Layout>
          <SEO
        title="Kontrola příspěvků"
      />

        <Section>
            <MainHeading heading="Schvalování příspěvků" section="Rozcestník">
              <p className="lg:w-2/3 mx-auto leading-relaxed text-base"></p>
            </MainHeading>
            <ImageText image={mainImg}>
            <p className="text-xl sm:text-3xl text-gray-600 font-bold text-center mx-4 my-8 lg:mx-16">Prosíme o strpení, nejsme roboti..</p>
            </ImageText>
            <div className="max-w-screen-md mx-auto">
              <ol className="block text-lg sm:text-xl">
                <Li>
                  <p>Před zveřejněním vašeho dotazu se moderátor podívá, zda je příspěvek čitelný, zda obsahuje veškeré potřebné informace a zda je v souladu s našimi pravidly.</p>
                </Li>
                <Li>
                  <p>Běžně se snažíme příspěvky kontrolovat v hodinových intervalech.  Někdy se však může čekací doba z různých důvodů prodloužit - a to až na 24 hodin.</p>
                  <p className="my-2 list-disc text-base">
                      V některých případech vám moderátor příspěvek vrátí a navrhne vám nějaké úpravy. Respektujte prosím poznámky moderátora, doplňte chybějící informace a upravte příspěvek tak, aby splňoval všechna naše pravidla. 
                      <br />
                      Nezlobte se a neberte si to osobně. Děláme to ve vašem zájmu - ve snaze předejít nepříjemným komentářům. Chceme tak zajistit co nejlepší technickou podporu.
                  </p>
                </Li>
              </ol>
              <UiLinkContainer>
                  <UiLink 
                  headerText="O automatické (okamžité) schvalování dotazů můžete zažádat zde"
                  link="https://m.me/uurav"
                  image={tickIcon}
                  />
              </UiLinkContainer>
            </div>
        </Section>
      </Layout>
      );
    }
    
export default KontrolaPrispevkuPage;
